.news-container{
    width: 100%;
    min-height: 500px;
    padding-bottom: 100px;
    background: rgb(228, 226, 226);
}
.iframe-wrapper-news{
    width: 90%;
    padding-left: 5%;
    padding-top: 30px;
}
.iframe-cmbt-news-comp{
    width: 30%;
    height: 500px;
    margin-left: 40px;
    display: inline-block;
    margin-top: 30px;
}
.image-wrapper-news{
    width: 90%;
    min-height: 200px;
    background: rgb(228, 226, 226);
    padding-left: 5%;
    padding-right: 5%;
}
.image-newspage{
    height: 300px;
    margin: 30px;
    cursor: zoom-in;
}
.background-image-popup{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(68, 68, 68, 0.596);
    overflow: auto;
    padding-top: 50px;
    padding-right: 50px;
}
.close-me{
    font-size: 70px;
    color: #111111;
    position: fixed;
    margin-left: 75%;
    cursor: pointer;
}
.popup-image{
    width: 80%;
}
.popup-image-wrapper{
    position: relative;
    margin-left: 5%;
    max-width: 80%;
    padding: 50px;
    background: #ffffff;
    padding-bottom: 100px;
}
