.navigation{
    width: 100%;
    padding-top: 20px;
    text-align: left;
    height: 80px;
    display: flex;
    flex-direction: row;
    background: #000000;
}

.link{
    color: #57a922 !important;
    font-size: 1.4rem;
    /* font-family: Helvetica; */
    text-decoration: none;
    /* margin-left: 50px; */
    /* margin-top: 30px; */
    font-weight: bold;
}
.text-link-wrap{
    margin-top: 30px;
    width: 200px;
    padding-left: 10px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    text-align: left;

}
.link:hover{
    font-size: 1.5rem;
    color: #c4e7ac !important;
    transition: all .5s ease-in-out;
    transform: scaleX(0);
    -webkit-transition: all .5s ease-in-out;
}
.logo-wrapper{
    margin-top: -33px;
    margin-left: 100px;
}
.logo{
    height: 100px;
}
.external-links{
    float: right;
    margin: auto;
    /* border: 1px solid wheat; */
    margin-right: 50px;
}
.first{
    padding-left: 60px !important;
    width: 150px !important;
}
.second{
    padding-left: 10px !important;
    width: 150px !important;
}
.second2{
    margin-left: -40px !important;
    width: 150px !important;
}
.third{
    width: 150px !important; 
}
.external-links img{
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
}
.language-container{
    /* position: absolute; */
    float: right;
    margin-right: 20px;
}