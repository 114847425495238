.footer-cont{
    width: 100%;
    height: 300px;
    background: #111111;
}
.upper-footer{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
}
.logo-footer{
    margin-left: 50px;
    color: #ffffff;
}
.logo-image{
    height: 150px;
    display: block;
}
.external-links2{
    float: right !important;
    /* padding-left: 100px; */
    padding-top: 20px;
    /* border: 1px solid red; */
    margin-left: 300px;
}
.external-links2 img{
    width: 30px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 30px;
}
.inner-links{
    width: 80%;
    display: flex;
    flex-direction: row;
    float: right;
}
.font-white{
    color: #8a8a8a !important;
    font-size: 1rem  !important;
    text-decoration: none;
    margin-left: 100px;
}
.float-right{
    float: right !important;
}
.lower-footer{
    font-size: 10px;
    color: #ffffff;
    text-align: center;
    padding-top: 20px;
}
.email-footer{
    color: rgb(63, 173, 247);
    margin-left: 5px;
    cursor: pointer;
}
