@media screen and (max-width: 1050px) {
  body {
    width: 1300px;
  }
}
.wrapper-mid{
    width: 100%;
    min-height: 500px;
    background: #ffffff;
}
.mid-container{
    width: 100%;
    min-height: 500px;
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: -50px;
}
.header-me{
    font-weight: 200;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 3px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    color: #111111;
    margin-left: 100px;
    margin-top: 50px;
}
.navigation-mid{
    margin-top: 0;
    width: 70px;
}
.link-me{
    cursor: pointer;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 20px;
    color: #3a3a3a;
}
.link-me:hover{
    font-size: 24px;
    color: #111111;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}
.product-container{
    width: 20%;
    margin-left: 10px;
}
.product-container-catalog{
    width: 100%;
    margin-left: 10px;
}
.poster-wrapper{
    overflow-y: hidden !important;
    width: 100%;
    height: 350px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* position: relative; */
}
.poster{
    height: 300px;
    /* cursor: pointer; */
}
.poster-two{
    cursor: pointer;
    height: 300px;
    /* position: absolute; */
}
/* .poster-two:hover{
    height: 310px;
    transition: all .5s ease-in-out;
    transform: scaleX(1);
    -webkit-transition: all .5s ease-in-out;
} */
.info-box{
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.home-container{
    width: 100%;
    height: 600px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
.video-frame{
    width: 100% !important;
    height: 700px;
    background: black;
    margin-top: -30px;
}
.cover-video{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.404);
    z-index: 1;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.title-of-video{
    position: absolute;
    width: 40%;
    height: 40px;
    font-size: 30px;
    color: #ffffff8a;
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    background:  #58a9221f;
    border: 2px solid  #58a92288;
    z-index: 2;
   text-align: center;
   padding-top: 10px;
   padding-bottom: 5px;
   margin-top: 35%;
   margin-left: 30%;
}
.container-about{
    min-height: 500px;
}
.images-about{
    width: 100%;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
}
.image-about{
    height: 180px;
    width: auto;
}
.contant-box-about{
    height: 400px;
    width: 80%;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    font-weight: bold;
}
.contant-box-about p{
    color: #3f3f3f;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 1.6rem;
    letter-spacing: 3px;
}
.contant-box-about h3{
    color: #3f3f3f;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 1.7rem;
    letter-spacing: 3px;
}
.navigation{
    width: 100%;
    padding-top: 20px;
    text-align: left;
    height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background: #000000;
}

.link{
    color: #57a922 !important;
    font-size: 1.4rem;
    /* font-family: Helvetica; */
    text-decoration: none;
    /* margin-left: 50px; */
    /* margin-top: 30px; */
    font-weight: bold;
}
.text-link-wrap{
    margin-top: 30px;
    width: 200px;
    padding-left: 10px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    text-align: left;

}
.link:hover{
    font-size: 1.5rem;
    color: #c4e7ac !important;
    transition: all .5s ease-in-out;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: all .5s ease-in-out;
}
.logo-wrapper{
    margin-top: -33px;
    margin-left: 100px;
}
.logo{
    height: 100px;
}
.external-links{
    float: right;
    margin: auto;
    /* border: 1px solid wheat; */
    margin-right: 50px;
}
.first{
    padding-left: 60px !important;
    width: 150px !important;
}
.second{
    padding-left: 10px !important;
    width: 150px !important;
}
.second2{
    margin-left: -40px !important;
    width: 150px !important;
}
.third{
    width: 150px !important; 
}
.external-links img{
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
}
.language-container{
    /* position: absolute; */
    float: right;
    margin-right: 20px;
}
.wrapper-language-component{
    width: 40px;
    height: 30px;
    cursor: pointer;
}
.show-lang{
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #ffffff;
    display: block;
    border: 1px solid #ffffff;
    padding: 3px;
    width: 20px;
    text-align: center;
}
.hided-lang{
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #ffffff;
    display: block;
    border: 1px solid #ffffff;
    padding: 3px;
    width: 20px;
    text-align: center;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-top: 4px solid #ffffff;
    margin-left: 5px;
    margin-top: 2px;
  }
.container-catalog-page{
    min-height: 500px;
    width: 100%;
}
.a111{
    display: inline-block;
}
.wrapper-catalog-page{
    width: 100%;
    /* display: flex; */
    position: relative;
}
.products-components-wr{
    display: inline-block !important;
    min-width: 300px;
    margin: 20px;
    position: static !important;
}
.wrapper-header-catalog{
    text-align: center;
    width: 100%;
    height: 100px;
}
.header-me-catalog{
    font-weight: 200;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 3px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    color: #111111;
    margin-top: 50px;
}
.product-show-details{
    display: none;
    -webkit-transform:translate(9999px);
            transform:translate(9999px);
}
.display{
    width: 95%;
    padding-left: 2%;
    padding-right: 2%;
    height: 400px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #111111;
    color: #ffffff;
    position: absolute;
    z-index: 3;
    margin: 0;
    margin: initial;
    margin-top: -50px;
    display: block !important;
    transition: display 1s linear;  
    -webkit-transform:translate(0);  
            transform:translate(0);
    border: 2px solid #ffffff;
}
.wrap-datails{
    position: absolute !important;
    border: 1px solid black;
    height: 10px;
}
.details-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.close-wrapper-detail{
    width: 10px;
    width: 100%;
}
.close-span{
    float: right;
    font-size: 35px;
    margin-right: 10px;
    cursor: pointer;
}
.left-image-side{
    width: 40%;
    display: inline-block;
}
.img-detail-wrapper{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 300px;
}
.img-detail{
    height: 300px;
}
.img-detail2{
    height: 200px;
}
.image-container-detail{
    height: 100px;
    width: 100%;
    border: 1px solid #ffffff;
    padding-top: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow-y: auto;
}
.subImages-detailpage{
    height: 80px;
    margin-left: 5px;
    cursor: pointer;
}
.middle-cast-wrapper{
    width: 20%;
    display: inline-block;
    /* padding-top: 50px; */
}
.last-detail-infos-wrapper{
    width: 30%;
    /* display: inline-block; */
    /* padding-top: 50px; */
    text-align: center;
    padding-left: 30px;
    
}
.last-detail-infos-wrapper p {
    color: rgb(190, 190, 190);
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B";
}
.last-detail-infos-wrapper-header{
    color: rgb(190, 190, 190);
    font-size: 16px;
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
}
.wrapper-props-details-info-zero{
    width: 105px;
}
.wrapper-props-details{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    color: rgb(190, 190, 190);
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    margin-left: 30px;
}
.wrapper-props-details-info-one{
    margin-left: 10px;
}
.wrapper-iframe-component{
    width: 100%;
    height: 100%;
}
.cbnmt-ifrm{
    width: 100%;
    height: 100%;
    margin: 0;
    border:none;
    cursor: pointer;
}
.news-container{
    width: 100%;
    min-height: 500px;
    padding-bottom: 100px;
    background: rgb(228, 226, 226);
}
.iframe-wrapper-news{
    width: 90%;
    padding-left: 5%;
    padding-top: 30px;
}
.iframe-cmbt-news-comp{
    width: 30%;
    height: 500px;
    margin-left: 40px;
    display: inline-block;
    margin-top: 30px;
}
.image-wrapper-news{
    width: 90%;
    min-height: 200px;
    background: rgb(228, 226, 226);
    padding-left: 5%;
    padding-right: 5%;
}
.image-newspage{
    height: 300px;
    margin: 30px;
    cursor: zoom-in;
}
.background-image-popup{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(68, 68, 68, 0.596);
    overflow: auto;
    padding-top: 50px;
    padding-right: 50px;
}
.close-me{
    font-size: 70px;
    color: #111111;
    position: fixed;
    margin-left: 75%;
    cursor: pointer;
}
.popup-image{
    width: 80%;
}
.popup-image-wrapper{
    position: relative;
    margin-left: 5%;
    max-width: 80%;
    padding: 50px;
    background: #ffffff;
    padding-bottom: 100px;
}

.footer-cont{
    width: 100%;
    height: 300px;
    background: #111111;
}
.upper-footer{
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.logo-footer{
    margin-left: 50px;
    color: #ffffff;
}
.logo-image{
    height: 150px;
    display: block;
}
.external-links2{
    float: right !important;
    /* padding-left: 100px; */
    padding-top: 20px;
    /* border: 1px solid red; */
    margin-left: 300px;
}
.external-links2 img{
    width: 30px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 30px;
}
.inner-links{
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    float: right;
}
.font-white{
    color: #8a8a8a !important;
    font-size: 1rem  !important;
    text-decoration: none;
    margin-left: 100px;
}
.float-right{
    float: right !important;
}
.lower-footer{
    font-size: 10px;
    color: #ffffff;
    text-align: center;
    padding-top: 20px;
}
.email-footer{
    color: rgb(63, 173, 247);
    margin-left: 5px;
    cursor: pointer;
}

