.container-catalog-page{
    min-height: 500px;
    width: 100%;
}
.a111{
    display: inline-block;
}
.wrapper-catalog-page{
    width: 100%;
    /* display: flex; */
    position: relative;
}
.products-components-wr{
    display: inline-block !important;
    min-width: 300px;
    margin: 20px;
    position: static !important;
}
.wrapper-header-catalog{
    text-align: center;
    width: 100%;
    height: 100px;
}
.header-me-catalog{
    font-weight: 200;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 3px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    color: #111111;
    margin-top: 50px;
}
.product-show-details{
    display: none;
    transform:translate(9999px);
}
.display{
    width: 95%;
    padding-left: 2%;
    padding-right: 2%;
    height: 400px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #111111;
    color: #ffffff;
    position: absolute;
    z-index: 3;
    margin: initial;
    margin-top: -50px;
    display: block !important;
    transition: display 1s linear;  
    transform:translate(0);
    border: 2px solid #ffffff;
}
.wrap-datails{
    position: absolute !important;
    border: 1px solid black;
    height: 10px;
}