.container-about{
    min-height: 500px;
}
.images-about{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
}
.image-about{
    height: 180px;
    width: auto;
}
.contant-box-about{
    height: 400px;
    width: 80%;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    font-weight: bold;
}
.contant-box-about p{
    color: #3f3f3f;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 1.6rem;
    letter-spacing: 3px;
}
.contant-box-about h3{
    color: #3f3f3f;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 1.7rem;
    letter-spacing: 3px;
}