.home-container{
    width: 100%;
    height: 600px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
.video-frame{
    width: 100% !important;
    height: 700px;
    background: black;
    margin-top: -30px;
}
.cover-video{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.404);
    z-index: 1;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.title-of-video{
    position: absolute;
    width: 40%;
    height: 40px;
    font-size: 30px;
    color: #ffffff8a;
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    background:  #58a9221f;
    border: 2px solid  #58a92288;
    z-index: 2;
   text-align: center;
   padding-top: 10px;
   padding-bottom: 5px;
   margin-top: 35%;
   margin-left: 30%;
}