.wrapper-mid{
    width: 100%;
    min-height: 500px;
    background: #ffffff;
}
.mid-container{
    width: 100%;
    min-height: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}
.header-me{
    font-weight: 200;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 3px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    color: #111111;
    margin-left: 100px;
    margin-top: 50px;
}
.navigation-mid{
    margin-top: 0;
    width: 70px;
}
.link-me{
    cursor: pointer;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 20px;
    color: #3a3a3a;
}
.link-me:hover{
    font-size: 24px;
    color: #111111;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}