.details-container{
    display: flex;
    flex-direction: row;
}
.close-wrapper-detail{
    width: 10px;
    width: 100%;
}
.close-span{
    float: right;
    font-size: 35px;
    margin-right: 10px;
    cursor: pointer;
}
.left-image-side{
    width: 40%;
    display: inline-block;
}
.img-detail-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.img-detail{
    height: 300px;
}
.img-detail2{
    height: 200px;
}
.image-container-detail{
    height: 100px;
    width: 100%;
    border: 1px solid #ffffff;
    padding-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}
.subImages-detailpage{
    height: 80px;
    margin-left: 5px;
    cursor: pointer;
}
.middle-cast-wrapper{
    width: 20%;
    display: inline-block;
    /* padding-top: 50px; */
}
.last-detail-infos-wrapper{
    width: 30%;
    /* display: inline-block; */
    /* padding-top: 50px; */
    text-align: center;
    padding-left: 30px;
    
}
.last-detail-infos-wrapper p {
    color: rgb(190, 190, 190);
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B";
}
.last-detail-infos-wrapper-header{
    color: rgb(190, 190, 190);
    font-size: 16px;
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
}
.wrapper-props-details-info-zero{
    width: 105px;
}
.wrapper-props-details{
    display: flex;
    flex-direction: row;
    color: rgb(190, 190, 190);
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    margin-left: 30px;
}
.wrapper-props-details-info-one{
    margin-left: 10px;
}