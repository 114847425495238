.wrapper-language-component{
    width: 40px;
    height: 30px;
    cursor: pointer;
}
.show-lang{
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #ffffff;
    display: block;
    border: 1px solid #ffffff;
    padding: 3px;
    width: 20px;
    text-align: center;
}
.hided-lang{
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #ffffff;
    display: block;
    border: 1px solid #ffffff;
    padding: 3px;
    width: 20px;
    text-align: center;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-top: 4px solid #ffffff;
    margin-left: 5px;
    margin-top: 2px;
  }