.product-container{
    width: 20%;
    margin-left: 10px;
}
.product-container-catalog{
    width: 100%;
    margin-left: 10px;
}
.poster-wrapper{
    overflow-y: hidden !important;
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* position: relative; */
}
.poster{
    height: 300px;
    /* cursor: pointer; */
}
.poster-two{
    cursor: pointer;
    height: 300px;
    /* position: absolute; */
}
/* .poster-two:hover{
    height: 310px;
    transition: all .5s ease-in-out;
    transform: scaleX(1);
    -webkit-transition: all .5s ease-in-out;
} */
.info-box{
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}