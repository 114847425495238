.wrapper-iframe-component{
    width: 100%;
    height: 100%;
}
.cbnmt-ifrm{
    width: 100%;
    height: 100%;
    margin: 0;
    border:none;
    cursor: pointer;
}